import React, { Fragment } from "react"
import { Link } from "gatsby"
import { Styled, css } from "theme-ui"

import Layout from "../layout/layout"
import SEO from "../seo"
import Container from "../layout/container/container"

const Posts = ({ location, data }) => {
  const {
    allBlogPost: { edges: posts },
    site: {
      siteMetadata: { title: siteTitle/*, social: socialLinks*/ },
    },
  } = data

  return (
    <Layout location={location} title={siteTitle}>
      <Container>
        {posts.map(({ node }) => {
          const title = node.title || node.slug
          const keywords = node.keywords || []
          return (
            <Fragment key={node.slug}>
              <SEO title="Home" keywords={keywords}/>
              <div>
                <Styled.h2
                  css={css({
                    mb: 1,
                  })}
                >
                  <Styled.a
                    as={Link}
                    css={{
                      textDecoration: `none`,
                    }}
                    to={node.slug}
                  >
                    {title}
                  </Styled.a>
                </Styled.h2>
                <small>{node.date}</small>
                <Styled.p>{node.excerpt}</Styled.p>
              </div>
            </Fragment>
          )
        })}
      </Container>
    </Layout>
  )
}

export default Posts
